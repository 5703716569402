<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp" style="min-height:90vh" class="pa-0">
    <v-card>
      <GameMeAndYou only-me/>
      <v-btn @click="hasHistory() ? $router.go(-1) : $router.push('/shop')" icon class="mr-3 ml-8 mt-3">
        <pg-icon XXL>i-go-back</pg-icon>
      </v-btn>
      Retour en arrière
      <v-card-title><span class="ma-auto">Conditions générales de vente</span></v-card-title>
      <v-card-text class="pa-16">
        <c-g-v-content/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import GameMeAndYou from "../components/game/GameMeAndYou";
import PgIcon from "../components/PGIcon";
import CGVContent from "../components/cgv/CGVContent";

export default {
  name: "CGV",
  components: {CGVContent, PgIcon, GameMeAndYou},
  methods: {
    hasHistory() {
      return window.history.length > 2
    }
  }
}
</script>

<style scoped>
span {
  font-weight: 400;
}
</style>